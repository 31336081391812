.mapbox_logo {
  position: fixed;
  z-index: 2;
  bottom: 16px;
  left: 16px;
  height: 24px;
  opacity: 0.4;
  cursor: pointer;
}
.mapbox_logo:hover {
  opacity: 1;
}
@media (max-width:730px) {
  .mapbox_logo {
    bottom: 140px;
  }
}
